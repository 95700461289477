import UArray from "@repo/utils/UArray";

namespace UEventTarget {
  export const wrap = <Target extends EventTarget>(eventTarget: Target) => {
    // TODO: Add more mappings
    type EventMap = Target extends Window
      ? WindowEventMap
      : HTMLElementEventMap;

    const events = {
      on<EventType extends keyof EventMap>(
        types: UArray.Maybe<EventType>,
        listener: (this: HTMLElement, e: EventMap[EventType]) => any,
      ) {
        for (const type of [types].flat()) {
          // @ts-expect-error
          eventTarget.addEventListener(type, listener);
        }
        return {
          off() {
            for (const type of [types].flat()) {
              // @ts-expect-error
              eventTarget.removeEventListener(type, listener);
            }
          },
        };
      },
      once<EventType extends keyof EventMap>(
        types: UArray.Maybe<EventType>,
        listener: (this: HTMLElement, e: EventMap[EventType]) => any,
      ) {
        const withOff: typeof listener = (e: any) => {
          res.off();
          // @ts-expect-error
          return listener.bind(eventTarget)(e);
        };

        const res = events.on(types, withOff);
        return res;
      },
    };
    return events;
  };
}

export default UEventTarget;
